import axios from "axios";
import api from "@/api";

export const removeMenuTimeCategory = ({ rootState }, body) => {
  return new Promise((resolve) => {
    axios
      .put(
        `${api}/r/${rootState.domainName}/v1/menu/update_menu`,
        body // Aquí se añadió 'params' como el cuerpo de la solicitud
        // rootState.businessAuth // Añadir 'headers' para la autenticación
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const updateMenuTime = ({ rootState }, body) => {
  return new Promise((resolve) => {
    axios
      .patch(
        `${api}/r/${rootState.domainName}/v2/menu/bulk_update`,
        body, // Aquí se añadió 'params' como el cuerpo de la solicitud
        rootState.businessAuth // Añadir 'headers' para la autenticación
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const createMenuTime = ({ rootState }, body) => {
  return new Promise((resolve) => {
    axios
      .post(
        `${api}/r/${rootState.domainName}/v1/menu`,
        body,
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};

export const fetchMenuTime = ({ commit, rootState }) => {
  return new Promise((resolve) => {
    axios
      .get(`${api}/r/${rootState.domainName}/v2/menu`, rootState.businessAuth)
      .then(
        (response) => {
          commit("setMenuTime", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};

export const fetchCategories = ({ commit, rootState }) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/categories`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setCategories", response);
          resolve(response);
        },
        (error) => {
          resolve(error.message);
        }
      );
  });
};
export const createCategory = ({ rootState }, payload) => {
  return new Promise((resolve) => {
    // Añadir branch_ids al FormData
    payload.append("branch_ids", rootState.currentBranch.id);

    axios
      .post(
        `${api}/r/${rootState.domainName}/v1/categories`,
        payload,
        rootState.businessAuth
      )

      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const updateCategory = ({ rootState }, payload) => {
  return new Promise((resolve) => {
    axios
      .patch(
        `${api}/r/${rootState.domainName}/v1/categories/${payload.id}`,
        payload.category,
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const fetchCategoriesWithProducts = ({ commit, rootState }) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/available-categories`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
          commit("setCategoriesWithProducts", response);
          commit("setOnlyProducts", response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const deleteCategory = ({ rootState }, payload) => {
  return new Promise((resolve) => {
    axios
      .delete(
        `${api}/r/${rootState.domainName}/v1/categories/${payload}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const deleteCategoryImg = ({ rootState }, payload) => {
  const { categoryId, formData } = payload;
  return new Promise((resolve) => {
    axios
      .put(
        `${api}/r/${rootState.domainName}/v1/categories/${categoryId}`,
        formData,
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
// export const deleteCategoryImg = ({ rootState }, payload) => {
//   const { categoryId, formData } = payload;
//   return new Promise((resolve) => {
//     axios.put(
//       `${api}/r/${rootState.domainName}/v1/categories/${categoryId}`,
//       formData,
//       rootState.businessAuth
//     );
//   }).then(
//     (response) => {
//       resolve(response);
//     },
//     (error) => {
//       resolve(error.response);
//     }
//   );
// };
